@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap"); /* #region Global Mixin Definitions*/
/* #endregion */
@import "./select2.css";
@import "../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-calendars/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-dropdowns/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-inputs/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-notifications/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-react-schedule/styles/bootstrap5.css";
* {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body.modal-open {
  overflow: hidden;
}

.container {
  position: absolute;
  width: calc(100% - 300px);
  left: 300px;
  top: 80px;
  transition: 0.5s;
  max-width: 100% !important;
}

.top-align-grid .e-rowcell {
  vertical-align: top !important;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #50697f;
  font-family: "robot", sans-serif;
  overflow-x: hidden;
}

.tiny {
  font-size: 0.75em;
}

html::-webkit-scrollbar {
  width: 0.5vw;
}

html::-webkit-scrollbar-thumb {
  background-color: #203a4e;
  border-radius: 10px;
}

html::-webkit-scrollbar-track {
  background-color: #50697f;
}

.close {
  border: none;
  background-color: #fff;
  opacity: 0.5;
  transition: ease-in-out all 0.3s;
}
.close:hover {
  opacity: 1;
}

.toast-success {
  background-color: #00b74a !important;
  color: #fff !important;
}
.toast-success .e-toast-title {
  color: #fff !important;
  font-size: 1.1em;
  font-weight: bold;
}
.toast-success .e-toast-content, .toast-success .e-toast-close-icon {
  color: #fff !important;
}

.toast-danger {
  background-color: #f93154 !important;
  color: #fff !important;
}
.toast-danger .e-toast-title {
  color: #fff !important;
  font-size: 1.1em;
  font-weight: bold;
}
.toast-danger .e-toast-content, .toast-danger .e-toast-close-icon {
  color: #fff !important;
}

.toast-info {
  background-color: #39c0ed !important;
  color: #fff !important;
}
.toast-info .e-toast-title {
  color: #fff !important;
  font-size: 1.1em;
  font-weight: bold;
}
.toast-info .e-toast-content, .toast-info .e-toast-close-icon {
  color: #fff !important;
}

.confirm-button {
  background-color: #00b74a !important;
  color: #fff !important;
}
.confirm-button::after {
  font-family: "Font Awesome 6 Free";
  content: "\f058";
  padding-left: 0.5em;
}

.cancel-button {
  background-color: #f93154 !important;
  color: #fff !important;
}
.cancel-button::after {
  font-family: "Font Awesome 6 Free";
  content: "\f057";
  padding-left: 0.5em;
}

.confirm-box {
  position: fixed !important;
  top: 45% !important;
  z-index: 99999;
}

h1 {
  color: #203a4e;
  font-family: "Quicksand", sans-serif;
}

h2 {
  color: #4b2f3d;
  font-family: "Quicksand", sans-serif;
}

h3 {
  color: #6a7987;
  font-family: "Quicksand", sans-serif;
}

.error-message,
.ErrorText {
  font-size: 0.75em;
  color: #f00;
  font-style: italic;
}

.e-tick-value {
  color: #aeaeae !important;
}

.text-orange {
  color: #e69c24;
}

.navbar-brand {
  color: #203a4e;
}
.navbar-brand span {
  color: #e69c24;
}

.login-title {
  color: #9eadbd;
  text-align: center;
}
.login-title h5 {
  color: #203a4e;
  margin: 0;
  padding: 0;
}
.login-title h5 span {
  color: #e69c24;
}

.version {
  color: #9eadbd;
  font-size: 0.75em;
  text-align: center;
  padding-top: 4px;
}

.login-background {
  /*background-image: url(../../public/img/login-background-3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;*/
}

.btn-primary {
  background-color: #203a4e !important;
}
.btn-primary:hover {
  background-color: #41506d !important;
}

.login-container {
  height: 100vh;
  width: 100vw;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.navContainer {
  position: relative;
  display: flex;
  width: 100%;
}

/* =============== Side Navigation ================ */
/*.navigation {
  position: fixed;
  width: 300px;
  height: 100vh;
  flex: 1;
  max-height: 100vh;
  overflow-y: auto;
  background: $col-primary;
  border-left: 10px solid $col-primary;
  transition: 0.5s;
  overflow-x: hidden;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $col-orange;
    border-radius: 5px;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 $col-orange;
}

.heading {
  white-space: nowrap;
}

.navigation.active {
  width: 80px;

  .heading {
    opacity: 0;
    transition: 0.5s ease-in-out;
    white-space: nowrap;
  }
}

.footer-heading {
  position: relative;
  left: -40px;
  z-index: 10;
  border-left: none;
}

.copyright {
  font-size: 14px;

  small {
    white-space: nowrap;
  }
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.navigation ul li:hover,
.navigation ul li.hovered {
  background-color: $col-orange;
}

.navigation ul li:nth-child(1) {
  margin-bottom: 30px;
  pointer-events: none;
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #f1f1f1;
}

.navigation ul li:hover a,
.navigation ul li.hovered a {
  color: $col-primary;
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.navigation ul li a .icon i {
  font-size: 1rem;
}

.title {*/
/*color: $col-primary;*/
/*& span {
    color: $col-orange;
  }
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  text-align: start;
  white-space: nowrap;
}*/
/* --------- curve outside ---------- */
/*.navigation ul li:hover a::before,
.navigation ul li.hovered a::before {
  content: "";
  position: absolute;
  right: 0;
  top: -34px;
  width: 35px;
  height: 35px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 30px 20px 0 10px $col-orange;
  z-index: -1;
  pointer-events: none;
}

.navigation ul li:hover a::after,
.navigation ul li.hovered a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -34px;
  width: 35px;
  height: 35px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 30px -20px 0 10px $col-orange;
  z-index: -1;
  pointer-events: none;
}*/
.navigation {
  position: fixed;
  width: 300px;
  height: 100vh;
  flex: 1;
  max-height: 100vh;
  overflow-y: auto;
  background: #203a4e;
  border-left: 10px solid #203a4e;
  transition: 0.5s;
  overflow-x: hidden;
  z-index: 1;
  scrollbar-width: thin;
  scrollbar-color: #888 #e69c24;
}
.navigation::-webkit-scrollbar {
  width: 4px;
}
.navigation::-webkit-scrollbar-thumb {
  background-color: #e69c24;
  border-radius: 5px;
}
.navigation.active {
  width: 80px;
}
.navigation.active .heading {
  opacity: 0;
  transition: 0.5s ease-in-out;
  white-space: nowrap;
}
.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  /*&:hover,
  &.hovered */
}
.navigation ul li.active {
  background-color: #e69c24;
}
.navigation ul li.active a {
  color: #203a4e !important;
}
.navigation ul li.active a::before {
  content: "";
  position: absolute;
  right: 0;
  top: -34px;
  width: 35px;
  height: 35px;
  background-color: transparent;
  border-radius: 50%;
  z-index: -1;
  pointer-events: none;
  box-shadow: 30px 20px 0 10px #e69c24;
}
.navigation ul li.active a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -34px;
  width: 35px;
  height: 35px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 30px -20px 0 10px #e69c24;
  z-index: -1;
  pointer-events: none;
}
.navigation ul li:nth-child(1) {
  margin-bottom: 30px;
  pointer-events: none;
}
.navigation ul li a {
  position: relative;
  display: flex;
  width: 100%;
  text-decoration: none;
  color: #f1f1f1;
  /* &.active {
    background-color: $col-orange;
    font-weight: bold;
  }*/
}
.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.navigation ul li a .icon i {
  font-size: 1rem;
}
.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  text-align: start;
  white-space: nowrap;
}
.navigation ul li a .title span {
  color: #e69c24;
}
.navigation ul li a:hover {
  color: #e69c24;
}

.heading {
  white-space: nowrap;
}

.footer-heading {
  position: relative;
  left: -40px;
  z-index: 10;
  border-left: none;
}

.copyright {
  font-size: 14px;
}
.copyright small {
  white-space: nowrap;
}

.title span {
  color: #e69c24;
}

/* ===================== Main ===================== */
.main {
  position: absolute;
  width: calc(100% - 300px);
  left: 300px;
  flex: 3;
  /*min-height: 100vh;*/
  background: #fff;
  transition: 0.5s;
  margin: 0 auto;
}

.main.active {
  width: calc(100% - 80px);
  left: 80px;
}

.container.active {
  width: calc(100% - 80px);
  left: 80px;
}

.topbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.toggle {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
}

.search {
  position: relative;
}

.search a {
  color: #203a4e;
  position: relative;
  width: 100%;
}

.user {
  position: relative;
  overflow: hidden;
  margin-right: 5%;
}
.user label {
  cursor: pointer;
}

/* ====================== Responsive Design ========================== */
@media (max-width: 991px) {
  .navigation {
    left: -300px;
  }
  .navigation.active {
    width: 300px;
    left: 0;
  }
  .main {
    width: 100%;
    left: 0;
  }
  .container {
    width: 100%;
    left: 0;
  }
  .main.active {
    left: 300px;
  }
  .container.active {
    left: 300px;
  }
  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .user {
    min-width: 40px;
    margin: 0;
  }
  .navigation {
    width: 100%;
    left: -100%;
    z-index: 1000;
  }
  .navigation.active {
    width: 500px;
    left: 0;
  }
  .toggle {
    z-index: 10001;
  }
  .main.active .toggle {
    color: #fff;
    position: fixed;
    right: 220px;
    left: initial;
  }
}
.panel {
  padding: 10px;
  color: #9eadbd;
  /*width: 100%;*/
  font-size: 12px;
  display: grid;
  max-height: 500px;
  grid-template-columns: repeat(3, 1fr);
}
.panel fieldset {
  display: flex;
  align-items: center;
}

.dd-btn {
  width: 100%;
  color: #333;
  border: 1px #ddd solid;
  background: transparent;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 5px;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.risk-btn-main {
  color: #333;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 4px;
  font-size: 16px;
  text-transform: capitalize;
  padding: 2px 20px;
  font-weight: bold;
}
.risk-btn-main:hover {
  color: #fff;
  background-color: #203a4e;
}

.empty-div {
  flex: 1;
}

.info-div {
  flex: 1;
}

.risk-btn-blue {
  background-color: skyblue;
  color: #111;
  border: none;
  font-weight: bold;
  font-size: 16px;
  padding: 0.15rem 0.75rem;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px 0;
}
.risk-btn-blue:hover {
  background-color: #71c5e7;
}

.risk-title-section {
  background-color: #203a4e;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  color: #fff;
}

.risk-update-section {
  background-color: #203a4e;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 85%;
}
.risk-update-section .risk-text {
  font-weight: bold;
  flex: 1;
  color: #fff;
  margin: 0;
  font-size: 22px;
  margin-right: 0.5rem;
  text-align: center;
}
.risk-update-section .risk-text em {
  font-size: 20px;
}
.risk-update-section div.col-md-5 {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  color: #fff;
  align-items: center;
}
.risk-update-section div.col-md-5 button.risk-btn-blue {
  background-color: skyblue;
  color: #111;
  border: none;
  font-weight: bold;
  font-size: 16px;
  padding: 0.15rem 0.75rem;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px 0;
}
.risk-update-section div.col-md-5 button.risk-btn-blue:hover {
  background-color: #71c5e7;
}

.risk-card hr {
  margin: 0.5rem 1.5rem;
  border-top: 1px solid #000;
}
.risk-card button {
  background-color: skyblue;
  color: #111;
  border: none;
  font-weight: bold;
  font-size: 16px;
  padding: 0.15rem 0.75rem;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px 0;
}
.risk-card button:hover {
  background-color: #71c5e7;
}

.blue-cards .card {
  background-color: #F0F8FF !important;
  border: 1px solid #ccc;
  /*    &.flat-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
  */
}
.blue-cards .card .card-header {
  font-weight: bold;
  padding-bottom: 0;
}
.blue-cards .card .card-header i {
  cursor: pointer;
  font-size: 20px;
}
.blue-cards .card .card-body {
  padding-bottom: 0;
}
.blue-cards .card .fa-trash-can, .blue-cards .card .fa-edit, .blue-cards .card .e-edit {
  cursor: pointer;
}
.blue-cards .card small {
  font-size: 0.7em;
  color: #999;
}

.flat-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.blue-card {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #F0F8FF !important;
}
.blue-card .card-header {
  font-weight: bold;
  padding: 0.5rem;
}
.blue-card .card-header i {
  cursor: pointer;
  font-size: 20px;
}
.blue-card .fa-trash-can {
  cursor: pointer;
}
.blue-card small {
  font-size: 0.7em;
  color: #999;
}

.risk-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
  /*gap: 25px;*/
}
.risk-container .box {
  margin-bottom: 25px;
}
.risk-container .card1 {
  min-width: 300px;
  min-height: 100%;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #F0F8FF !important;
  /*button.btn {
    background-color: skyblue;
    color: #111;
    border: none;
    //font-weight: bold;
    font-weight: normal;
    font-size: 12px;
    padding: 0.20rem 0.75rem;
    cursor: pointer;
    border-radius: 4px;
    margin: 0;
    text-transform: uppercase;

    &:hover {
      background-color: darken(skyblue, 5%);
    }
  }*/
}
.risk-container .card1 .card-header {
  font-weight: bold;
  padding: 0.5rem;
}
.risk-container .card1 .card-header i {
  cursor: pointer;
  font-size: 20px;
}
.risk-container .card1 .card-body {
  padding: 10px 15px;
}
.risk-container .card1 .risk-body {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 0;
}
.risk-container .card1 .risk-body small {
  font-size: 0.75em;
  color: #999;
}
.risk-container .card1 .risk-body div.d-flex i {
  cursor: pointer;
}

.vertical {
  border-left: 1px solid #999;
  height: 100%;
}
@media (max-width: 768px) {
  .vertical {
    border: none;
  }
}

.risk-status div i {
  font-size: 20px;
  position: relative !important;
  padding: 0 8px;
  cursor: pointer;
}
.risk-status div i span {
  width: 25px;
  height: 20px;
  color: #fff;
  padding: 4px 0px;
  background: #203a4e !important;
  font-size: 12px;
}
.risk-status .risk-status-box {
  padding: 4px 15px;
  color: #fff;
}
.risk-status .risk-status-box.active {
  background-color: limegreen;
  box-shadow: 0 4px 9px -4px limegreen;
}
.risk-status .risk-status-box.deactivated {
  background-color: #999;
  box-shadow: 0 4px 9px -4px #999;
}
.risk-status .risk-status-box.draft {
  background-color: #ff8c00;
  box-shadow: 0 4px 9px -4px #ff8c00;
}

.risk-header {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.risk-header .risk-box {
  width: 120px;
  margin: 4px;
  text-align: center;
}
.risk-header .risk-box small {
  font-weight: bold;
}
.risk-header .risk-box div {
  padding: 5px;
  background-color: #203a4e;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  box-shadow: 0 4px 9px -4px #000;
}
.risk-header .risk-box div:hover {
  background-color: #4fabc9;
}

.control-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .control-header div {
    width: 100%;
    margin-bottom: 8px;
  }
}

.assurance-header {
  display: flex;
  flex-wrap: wrap;
}
.assurance-header .col-md-7 {
  align-items: end;
}
.assurance-header .gap-4 {
  align-items: center;
}
@media (max-width: 768px) {
  .assurance-header .col-md-7 {
    width: 100%;
    align-items: end;
  }
}
@media (max-width: 375px) {
  .assurance-header .col-md-7 {
    flex-direction: column !important;
    align-items: start;
  }
  .assurance-header .gap-4 {
    width: 100%;
    /*flex-direction: column !important;*/
    justify-content: space-between;
    align-items: center;
    gap: 5px !important;
  }
}

.assurance-card {
  background: lightgrey;
}

.process-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /*gap: 5px;*/
}
.process-card a {
  width: 500px;
}

.home-styles .card {
  display: block;
  text-align: center;
  color: #203a4e;
  /*a {
    color: $col-primary;
  }*/
  transition: ease-in-out all 0.3s;
}
.home-styles .card:hover {
  background-color: #203a4e;
  color: #fff;
}
.home-styles .card:hover a {
  color: #fff;
}

.login a:hover {
  color: #e69c24;
}

.required-icon {
  margin: 0 2px;
  color: #f00;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
.checkbox-grid .checkbox {
  margin-bottom: 10px;
}
.checkbox-grid label {
  position: relative;
  cursor: pointer;
  font-size: 12px;
}
.checkbox-grid label input {
  margin-right: 5px;
}

.footer-bottom {
  display: grid;
  height: 100%;
  align-content: end;
}

.chart-styles strong {
  display: block;
  width: 550px;
}

.loading {
  position: fixed;
  display: block;
  z-index: 10000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  color: #fff;
  text-align: center;
  font-size: 2em;
  padding-top: 80px;
}

.targets {
  width: 35%;
}
@media (max-width: 768px) {
  .targets {
    width: 60%;
  }
}
@media (max-width: 375px) {
  .targets {
    width: 100%;
  }
}

.rating-heading .rating-field {
  /*color: #fff;*/
  width: 150px;
  /*background: $col-primary;*/
  border-radius: 5px;
  padding: 0px 10px;
  text-align: center;
}

.text-small {
  font-size: 0.8em !important;
}

.findings-attachements i {
  font-size: 20px;
  position: relative !important;
  padding: 0 8px;
  cursor: pointer;
}
.findings-attachements i span {
  width: 25px;
  height: 20px;
  color: #fff;
  padding: 4px 0px;
  background: #203a4e !important;
  font-size: 12px;
}

.tooltip {
  position: relative;
  display: block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: white;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-top: 5px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.risk-heading {
  background-color: #ddd;
}

.e-grid .e-unboundcelldiv > button {
  margin: 0;
}

.e-flat.e-icon-btn {
  margin: 0;
  padding: 4px 6px;
}

.btn-structures {
  display: inline-block;
  margin: 0 2px;
  width: 24px;
  height: 22px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  line-height: 19px;
}
.btn-structures:hover {
  background-color: #aaa;
}

.btn-engage {
  display: inline-block;
  margin: 0 2px;
  text-align: center;
  background-color: #203a4e;
  border: none;
  font-weight: normal;
}
.btn-engage:hover {
  background-color: #aaa;
}

.text-tiny {
  font-size: 0.75em !important;
}

.card.risk-detail .card-body {
  padding: 0.25em 15px;
  font-size: 0.8em;
  color: #777;
}
.card.risk-detail .card-footer {
  padding: 0.25em 15px;
  font-weight: 300;
  background-color: #dddddd;
  border-top: 1px solid #aaa;
  font-size: 0.9em;
}

.colour-block {
  width: 24px;
  height: 24px;
  border: 1px solid #000;
}

.small-label {
  font-size: 0.75em;
  font-weight: 500;
  color: #212529;
  line-height: 24px;
}

.card-bordered {
  border: 1px solid #ddd;
}
.card-bordered .card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}
.card-bordered .card-title {
  margin: 0;
  padding: 0;
}

.ratetable::placeholder {
  color: red;
}

.DataGridTop .e-rowcell {
  vertical-align: top !important;
}

.hover-edit {
  position: relative;
}
.hover-edit .edit-button {
  opacity: 0;
  visibility: hidden;
  transition: ease-in-out all 0.3s;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 1px solid #000;
  top: 0;
  right: 0;
  cursor: pointer;
  text-align: center;
  padding-top: 2px;
  color: #fff;
  background-color: #203a4e;
}
.hover-edit .edit-button:hover {
  transition: ease-in-out all 0.3s;
  color: #000;
  background-color: #e69c24;
}
.hover-edit:hover .edit-button {
  opacity: 1;
  visibility: visible;
}

.control-table .e-grid .e-rowcell {
  background-color: #F0F8FF !important;
}

.table-highlight {
  width: 100%;
}
.table-highlight tr.selected {
  background-color: lightgreen;
}
.table-highlight tr.selected td {
  background-color: lightgreen !important;
}

.small-label label {
  font-size: 0.9em;
  color: #999;
  display: block;
}

.assurances-table {
  width: 100%;
}
.assurances-table td:first-child {
  font-weight: bold; /* Make the first <td> bold */
  width: 30%;
  padding-right: 15px;
  vertical-align: top;
  text-align: right;
  padding-bottom: 8px;
}
.assurances-table td:last-child {
  vertical-align: top;
  padding-bottom: 8px;
}

.risk-rating-table tbody tr:last-child {
  border-color: #000;
}
.risk-rating-table tfoot tr {
  border-color: #fff;
}
.risk-rating-table tfoot td {
  text-shadow: none;
}

.pointer {
  cursor: pointer;
}

.text-description {
  color: #aaa;
  font-size: 0.75em;
}

.text-dropdown .title {
  margin: 0;
  padding: 0;
}
.text-dropdown .description {
  color: #aaa;
  font-size: 0.75em;
}

.dropdown-detail {
  color: #999;
  font-size: 0.75em;
  line-height: normal !important;
  margin-bottom: 8px;
}

.tooltip-normal {
  font-size: 14px;
}

.table-risk-summary {
  font-size: 0.9em;
}
.table-risk-summary th {
  font-weight: bold;
  background-color: #eee;
  font-size: 0.8em;
}
.table-risk-summary td .risk-box {
  width: 120px;
  margin: 4px;
  text-align: center;
}
.table-risk-summary td .risk-box small {
  font-weight: bold;
}
.table-risk-summary td .risk-box div {
  padding: 5px;
  background-color: #203a4e;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  box-shadow: 0 4px 9px -4px #000;
}
.table-risk-summary td .risk-box div:hover {
  background-color: #4fabc9;
}

.evaluation-container .border-evaluation {
  border: 1px solid;
}
.evaluation-container .border-evaluation.potential {
  background-color: #fcf4d9 !important;
  border-color: #f1c232 !important;
}
.evaluation-container .border-evaluation.actual {
  background-color: #e0dbee !important;
  border-color: #b4a7d6 !important;
}
.evaluation-container .border-evaluation.target {
  background-color: #f9fcf8 !important;
  border-color: #93c47d !important;
}
.evaluation-container .bg-evaluation-border {
  border: 1px solid #000;
}
.evaluation-container .bg-evaluation h3 {
  color: #000 !important;
  font-weight: bold;
}
.evaluation-container .bg-evaluation small {
  text-transform: uppercase;
}
.evaluation-container .bg-evaluation th {
  font-weight: bold;
}
.evaluation-container .bg-evaluation .transparent.card {
  background-color: transparent !important;
}
.evaluation-container .bg-evaluation.potential {
  background-color: #f1c232 !important;
}
.evaluation-container .bg-evaluation.actual {
  background-color: #b4a7d6 !important;
}
.evaluation-container .bg-evaluation.target {
  background-color: #93c47d !important;
}
.evaluation-container .alert-gap {
  background-color: #d5a6bd;
  border: 1px solid #000;
  border-radius: 5px;
  width: 80%;
  margin: 0 auto;
}

.table-descriptions {
  table-layout: fixed;
}
.table-descriptions .content-width {
  width: 250px !important;
}
